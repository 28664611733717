import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Mailto from "../components/mailto"

import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

import "../css/landing.scss"

const IndexPage = () => (
  <Layout mainClass='d-flex'>
    <Seo title="Home" />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
    <Container className='m-auto'>
      <Row>
        <h1 className='jumbo'>Coming soon.</h1>
        {/* <h3>Stay tuned!</h3> */}
        <p>We're enroute, counting down to the launch of our pilot project.</p>
        <p>If you're interested in an app for your own event, or for any other inquiries, please contact us.</p>
        {/* <p><Button variant="secondary" className="rounded-pill">GET IN TOUCH</Button></p> */}
        <p><Mailto email="contact@toureka.app" className="btn btn-secondary rounded-pill">GET IN TOUCH</Mailto></p>
      </Row>
    </Container>
    {/* <div class='m-auto'>
      <Jumbotron>
        <h1>Hello, world!</h1>
        <p>
          This is a simple hero unit, a simple jumbotron-style component for calling
          extra attention to featured content or information.
        </p>
        <p>
          <Button variant="primary" className="rounded-pill">Learn more</Button>
        </p>
      </Jumbotron>
    </div> */}
    {/* <Container className='h-100'>
      <Row>
        <Col>
        <p>Hello</p>
        </Col>
      </Row>
    </Container> */}
  </Layout>
)

export default IndexPage
